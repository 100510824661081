import React, {useEffect} from "react";
import {DeliveryGroup, DeliveryMethodInterface} from "../../../../../interfaces/delivery-method.interface";
import {RadioGroup} from "@headlessui/react";
import CartDeliveryMethod from "./cart-delivery-method.component";
import {addDeliveryMethodToCart} from "../../../../../store/actions/cart.action";
import {translationHelper} from "../../../../../helpers/translation.helper";
import {useTranslation} from "react-i18next";
import {CartService} from "../../../../../services/cart.service";
import {TranslationKeys} from "../../../../../interfaces/translation-keys.enum";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/use-redux.hook";

interface DeliveryMethodCategoryProps {
    title: string;
    delivery_group: DeliveryGroup;
}

const DeliveryMethodCategory = (props: DeliveryMethodCategoryProps): JSX.Element => {

    const {title, delivery_group} = props;

    const dispatch = useAppDispatch();
    const {t, i18n} = useTranslation();

    const cart = useAppSelector(state => state.cart);

    const [selectedMethod, setSelectedMethod] = React.useState<DeliveryMethodInterface | undefined>(() => {
        const cartMethod = cart.delivery_methods.find(dg => dg.delivery_group === delivery_group);
        if (!cartMethod) return;

        const selectedDeliveryMethod = cart.available_delivery_methods.find(method => method.id === cartMethod.delivery_method.id);
        if (selectedDeliveryMethod) {
            return selectedDeliveryMethod;
        }
    });

    const [availableDeliveryMethods, setAvailableDeliveryMethods] = React.useState<DeliveryMethodInterface[]>([]);

    const cartService = new CartService();

    useEffect(() => {
        const methods = cartService.buildDeliveryMethods(delivery_group, cart.items.map(item => item.product));
        setAvailableDeliveryMethods(methods);
    }, [cart.available_delivery_methods.length]);

    const changeMethod = async (method: DeliveryMethodInterface) => {
        await dispatch(addDeliveryMethodToCart(method, delivery_group));
        setSelectedMethod(method)
    }

    useEffect(() => {
        const productGroup = cartService.getProductTypeGroupByDeliveryGroup(delivery_group);
        const items = cart.items.filter(item => productGroup.includes(item.product.type));
        if (items.length === 0) {
            return;
        }
        if (availableDeliveryMethods.length === 1) {
            changeMethod(availableDeliveryMethods[0]);
        }
    }, [availableDeliveryMethods])


    // Piilotetaan koko kategoria, jos ostoskorissa ei ole kyseiselle toimitustapa/tuoteryhmälle liittyviä tuotteita
    const productGroup = cartService.getProductTypeGroupByDeliveryGroup(delivery_group);
    const items = cart.items.filter(item => productGroup.includes(item.product.type));
    if (items.length === 0) {
        return <></>;
    }

    if (availableDeliveryMethods.length === 0) {
        return <div className="card">
            <div className="card-title">{translationHelper(title, i18n.language)}</div>
            <div className="card-content border-t border-gray-200">
                <p>{t(TranslationKeys.CART_DELIVERY_METHODS_NOT_FOUND)}</p>
            </div>
        </div>;
    }

    return <div className="card pt-5">
        <RadioGroup value={selectedMethod} onChange={changeMethod}>
            <RadioGroup.Label className="card-title">{translationHelper(title, i18n.language)}</RadioGroup.Label>

            <div
                className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4 card-content border-t border-gray-200">
                {availableDeliveryMethods.map((deliveryMethod, i) =>
                    <CartDeliveryMethod deliveryMethod={deliveryMethod} key={i + deliveryMethod.id}/>)}
            </div>
        </RadioGroup>
    </div>;

};

export default DeliveryMethodCategory;
