import React from "react";
import { priceHelper } from "../../../../helpers/price.helper";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "../../../../interfaces/translation-keys.enum";
import { useAppSelector } from "../../../../hooks/use-redux.hook";

interface CartCustomerProps {
}

const CartTotal: React.FC = (props: CartCustomerProps): JSX.Element => {

    const cart = useAppSelector(state => state.cart);
    const { t } = useTranslation();

    const calculateTotalDeliveryMethods = (): number => {
        return cart.delivery_methods.reduce((price, method) => price + (cart.available_delivery_methods?.find(am => am.id === method.delivery_method.id)?.product?.price || 0), 0);
    };

    const calculateTotalItems = (): number => {
        return cart.items.reduce((price, item) => price + item.price, 0);
    };

    return <dl className="border-t border-gray-200 py-6 px-4 space-y-6 sm:px-6">
        <div className="flex items-center justify-between">
            <dt className="text-sm">{t(TranslationKeys.ORDER_TOTAL_ITEMS)}</dt>
            <dd className="text-sm font-medium text-gray-900">{priceHelper(calculateTotalItems())}</dd>
        </div>
        <div className="flex items-center justify-between">
            <dt className="text-sm">{t(TranslationKeys.ORDER_TOTAL_DELIVERY)}</dt>
            <dd className="text-sm font-medium text-gray-900">{priceHelper(calculateTotalDeliveryMethods())}</dd>
        </div>
        <div className="flex items-center justify-between border-t border-gray-200 pt-6">
            <dt className="text-base font-medium">{t(TranslationKeys.ORDER_TOTAL)}</dt>
            <dd className="text-base font-medium text-gray-900">{priceHelper(cart.totalEur)}</dd>
        </div>
    </dl>;
};

export default CartTotal;
