import React from "react";
import CartDeliveryMethods from "./delivery-methods/cart-delivery-methods.component";
import CartCustomer from "./cart-customer.component";
import CartItems from "./cart-items/cart-items.component";
import CartTotalComponent from "./cart-total.component";
import Layout from "../../../layout.component";
import { CartStep } from "../../../../interfaces/cart-step";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "../../../../interfaces/translation-keys.enum";
import CartIsEmpty from "../cart/cart-is-empty.component";
import { useAppSelector } from "../../../../hooks/use-redux.hook";

interface CheckoutProps {
}

const Checkout = (props: CheckoutProps): JSX.Element => {

    const cart = useAppSelector(state => state.cart);
    const { t } = useTranslation();

    if (cart.items.length === 0) {
        return <CartIsEmpty />;
    }

    return <Layout>
        <div className="max-w-2xl mx-auto py-6 lg:pt-16 lg:pb-24 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            <div className="lg:grid lg:grid-cols-12 lg:gap-x-12 xl:gap-x-16">
                <div className="lg:col-span-7">
                    {cart.step === CartStep.CUSTOMER_DETAILS && <CartCustomer />}
                    {cart.step === CartStep.DELIVERY_DETAILS && <CartDeliveryMethods />}
                </div>
                <div className="lg:col-span-5 mt-4 lg:mt-0">
                    <div className="card">
                        <h2 className="card-title">{t(TranslationKeys.ORDER_SUMMARY)}</h2>
                        <CartItems />
                        <CartTotalComponent />

                        <div className="border-t border-gray-200 py-6 px-4 sm:px-6">
                            {cart.step === CartStep.CUSTOMER_DETAILS && <CartCustomer.Buttons />}
                            {cart.step === CartStep.DELIVERY_DETAILS && <CartDeliveryMethods.Buttons />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>;
};
export default Checkout;
