import React from "react";
import CartItem from "./cart-item.component";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "../../../../../interfaces/translation-keys.enum";
import { useAppSelector } from "../../../../../hooks/use-redux.hook";

interface CartItemsProps {
}

const CartItems: React.FC = (props: CartItemsProps): JSX.Element => {

    const cart = useAppSelector(state => state.cart);

    const { t } = useTranslation();

    return <>
        <h3 className="sr-only">{t(TranslationKeys.CART_ITEMS_IN_CART)}</h3>
        <ul className="divide-y divide-gray-200 border-t border-gray-200">
            {cart.items.map((item, i) => <CartItem item={item} key={i + item.id} />)}
        </ul>
    </>;
};

export default CartItems;
