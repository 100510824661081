import React from "react";
import {RadioGroup} from "@headlessui/react";
import {CheckCircleIcon} from "@heroicons/react/solid";
import {useTranslation} from "react-i18next";
import {translationHelper} from "../../../../../helpers/translation.helper";
import {priceHelper} from "../../../../../helpers/price.helper";
import {DeliveryMethodInterface} from "../../../../../interfaces/delivery-method.interface";
import {classNames} from "@eventiofi/eventio-commons-ui";

interface CartDeliveryMethodProps {
    deliveryMethod: DeliveryMethodInterface
}

const CartDeliveryMethod = (props: CartDeliveryMethodProps): JSX.Element => {

    const {deliveryMethod} = props;

    const {i18n} = useTranslation();

    return <RadioGroup.Option
        key={deliveryMethod.id}
        value={deliveryMethod}
        className={({checked, active}) =>
            classNames(
                checked ? 'border-transparent' : 'border-gray-300',
                active ? 'ring-2 ring-primary-500' : '',
                'relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none'
            )
        }
    >
        {({checked, active}) => (
            <>
                <div className="flex-1 flex">
                    <div className="flex flex-col">
                        <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                            {translationHelper(deliveryMethod.title, i18n.language)}
                        </RadioGroup.Label>
                        <RadioGroup.Description
                            as="span"
                            className="mt-1 flex items-center text-sm text-gray-500"
                        >
                            {translationHelper(deliveryMethod.description, i18n.language)}
                        </RadioGroup.Description>
                        <RadioGroup.Description as="span" className="mt-6 text-sm font-medium text-gray-900">
                            {priceHelper(deliveryMethod.product?.price)}
                        </RadioGroup.Description>
                    </div>
                </div>
                {checked ? <CheckCircleIcon className="h-5 w-5 text-primary-600" aria-hidden="true"/> : null}
                <div
                    className={classNames(
                        active ? 'border' : 'border-2',
                        checked ? 'border-primary-500' : 'border-transparent',
                        'absolute -inset-px rounded-lg pointer-events-none'
                    )}
                    aria-hidden="true"
                />
            </>
        )}
    </RadioGroup.Option>
}

export default CartDeliveryMethod;
