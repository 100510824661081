import React from "react";
import {ProductInterface, ProductType} from "../../../../../interfaces/product.interface";
import {TranslationKeys} from "../../../../../interfaces/translation-keys.enum";
import {useTranslation} from "react-i18next";

interface ItemAmountSelectorProps {
    onAmountChange: (value: string) => void,
    amount: number
    product: ProductInterface
}

const UncontrollableItemAmount = (props: { amount: number }): JSX.Element => {
    const {t} = useTranslation();
    const {amount} = props;
    return <div title={t(TranslationKeys.CART_ITEM_AMOUNT)}>
        <select
            className="rounded-md border border-gray-300 text-base font-medium text-gray-700 text-left shadow-sm sm:text-sm bg-gray-50"
            value={amount}
            onChange={() => {}}
            disabled={true}
            title={t(TranslationKeys.COMMON_CANNOT_EDIT_ITEM_AMOUNT)}
        >
            <option value={amount}>{amount}</option>
        </select>
    </div>
}

const ItemAmountSelector = (props: ItemAmountSelectorProps): JSX.Element => {

    const {product, onAmountChange, amount} = props;
    const {t} = useTranslation();

    if (!product.availability) {
        return <UncontrollableItemAmount amount={amount}/>
    }

    if (product.availability.max === undefined || product.availability.step === undefined || product.availability.min === undefined) {
        return <UncontrollableItemAmount amount={amount}/>;
    }

    if (product.type !== ProductType.MERCHANDISE) {
        return <UncontrollableItemAmount amount={amount}/>
    }

    const amounts = [];

    for (let i = product.availability.min; i <= product.availability.max; i += product.availability.step) {
        amounts.push(<option value={i} key={`${product.id}_${i}`}>{i}</option>)
    }

    return <div>
        <label htmlFor={`${product.id}_quantity`} className="sr-only">
            {t(TranslationKeys.CART_ITEM_AMOUNT)}
        </label>
        <select
            id={`${product.id}_quantity`}
            name={`${product.id}_quantity`}
            className="rounded-md border border-gray-300 text-base font-medium text-gray-700 text-left shadow-sm focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
            value={amount}
            onChange={event => onAmountChange(event.target.value)}
        >
            {amounts}
        </select>
    </div>
}

export default ItemAmountSelector;
