import React, {FormEvent, useState} from "react";
import {useTranslation} from "react-i18next";
import {CustomerDetailsForm} from "../../../../interfaces/config.interface";
import {FormBuilder, IFormField, IFormGroup} from "@eventiofi/eventio-form-builder";
import {useConfig} from "../../../../hooks/use-config.hook";
import {CartService} from "../../../../services/cart.service";
import {LoadingSpinnerIcon} from "../../../icons/loading-spinner.icon";
import {addCommentsByCustomer, addCustomerToCart} from "../../../../store/actions/cart.action";
import {Link} from "gatsby";
import {CartCustomerType, CustomerInterface} from "../../../../interfaces/customer.interface";
import {TranslationKeys} from "../../../../interfaces/translation-keys.enum";
import {ICartItem} from "../../../../interfaces/cart-item.interface";
import {useAppDispatch, useAppSelector} from "../../../../hooks/use-redux.hook";

interface CartCustomerProps {
}

enum FormGroup {
    NAMES = "names",
    ADDRESS_LINES = "address_lines",
    POSTAL_INFO = "postal_info",
    GENERAL = "general",
    SEPARATE_DELIVERY_CHECKBOX = "separate_delivery_checkbox",
    CHECKBOXES = "checkboxes"
}

const formConfig: IFormField[] = [
    {
        fieldKey: "first_name",
        title: TranslationKeys.COMMON_FIRST_NAME,
        type: "text",
        grouped_by: FormGroup.NAMES,
        sort_order: 0,
        props: {
            input: {
                autoComplete: "given-name",
            },
        },
    },
    {
        fieldKey: "last_name",
        title: TranslationKeys.COMMON_LAST_NAME,
        type: "text",
        props: {
            input: {
                autoComplete: "family-name",
            },
        },
        grouped_by: FormGroup.NAMES,
        sort_order: 1,
    },
    {
        fieldKey: "address_line1",
        title: TranslationKeys.COMMON_ADDRESS_LINE_FIRST,
        type: "text",
        props: {
            input: {
                autoComplete: "address-line1",
                className: "block w-full border-gray-300 rounded-t-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm peer relative focus:z-10",
            },
        },
        grouped_by: FormGroup.ADDRESS_LINES,
        sort_order: 2,
    },
    {
        fieldKey: "address_line2",
        type: "text",
        props: {
            input: {
                autoComplete: "address-line2",
                className: "block w-full border-gray-300 rounded-b-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm peer relative focus:z-10",
            },
        },
        grouped_by: FormGroup.ADDRESS_LINES,
        sort_order: 3,
    },
    {
        fieldKey: "postal_code",
        title: TranslationKeys.COMMON_POSTAL_CODE,
        type: "text",
        props: {
            input: {
                autoComplete: "postal-code",
            },
        },
        grouped_by: FormGroup.POSTAL_INFO,
        sort_order: 4,
    },
    {
        fieldKey: "city",
        title: TranslationKeys.COMMON_CITY,
        type: "text",
        props: {
            input: {
                autoComplete: "city",
            },
        },
        grouped_by: FormGroup.POSTAL_INFO,
        sort_order: 5,
    },
    {
        fieldKey: "country",
        title: TranslationKeys.COMMON_COUNTRY,
        type: "select",
        props: {
            input: {
                autoComplete: "country-name",
            },
        },
        grouped_by: FormGroup.POSTAL_INFO,
        sort_order: 6,
        values: [
            {
                value: "FI",
                title: {
                    fi: "Suomi",
                },
            },
        ],
    },
    {
        fieldKey: "email",
        title: TranslationKeys.COMMON_EMAIL,
        type: "email",
        props: {
            input: {
                autoComplete: "email",
            },
        },
        grouped_by: FormGroup.GENERAL,
        sort_order: 7,
    },
    {
        fieldKey: "phone",
        title: TranslationKeys.COMMON_PHONE,
        type: "tel",
        props: {
            input: {
                autoComplete: "tel",
            },
        },
        grouped_by: FormGroup.GENERAL,
        sort_order: 8,
    },
    {
        fieldKey: "company_name",
        title: TranslationKeys.COMMON_COMPANY_NAME,
        type: "text",
        grouped_by: FormGroup.GENERAL,
        sort_order: 9,
    },
];

const formGroups: IFormGroup[] = [
    {
        groupKey: FormGroup.NAMES,
        sort_order: 1,
        classes: {
            parent: "grid grid-cols-2 gap-4",
            field: "col-span-2 sm:col-span-1",
        },
    },
    {
        groupKey: FormGroup.ADDRESS_LINES,
        sort_order: 2,
        classes: {
            parent: "grid grid-cols-1 -space-y-px",
            field: "col-span-1",
        },
    },
    {
        groupKey: FormGroup.POSTAL_INFO,
        sort_order: 3,
        classes: {
            parent: "grid grid-cols-3 gap-4",
            field: "col-span-3 sm:col-span-1",
        },
    },
    {
        groupKey: FormGroup.GENERAL,
        sort_order: 4,
        classes: {
            parent: "grid grid-cols-1 gap-4",
            field: "col-span-1",
        },
    },
    {
        groupKey: FormGroup.SEPARATE_DELIVERY_CHECKBOX,
        sort_order: 5,
        classes: {
            parent: "grid grid-cols-1 gap-4",
            field: "col-span-1",
        },
    },
    {
        groupKey: FormGroup.CHECKBOXES,
        sort_order: 6,
        classes: {
            parent: "grid grid-cols-1 gap-4",
            field: "col-span-1",
        },
    },
];

const formId = "customer-info-form";

const Buttons = (): JSX.Element => {

    const {isLoading} = useAppSelector(state => state.cart);
    const {t} = useTranslation();

    return <div className="w-full grid grid-cols-2 gap-4">
        <Link
            to="/cart"
            className="btn btn-secondary block text-center"
        >
            {t(TranslationKeys.PREVIOUS_STEP)}
        </Link>
        <button
            type="submit"
            className="btn btn-primary btn-icon btn-icon-left"
            disabled={isLoading}
            title={isLoading ? "Odota hetki..." : undefined}
            form={formId}
        >
            {isLoading && <LoadingSpinnerIcon className="animate-spin-slow text-white"/>}
            {t(TranslationKeys.NEXT_STEP)}
        </button>
    </div>;
};

const deliveryCustomerPrefix = CartCustomerType.DELIVERY;

const BuildFormGroups = (separateDeliveryAddress: boolean): IFormGroup[] => {

    const {t} = useTranslation();

    let groups: IFormGroup[] = [...formGroups];

    let lastShortOrder = formGroups[formGroups.length - 2].sort_order + 1;

    if (separateDeliveryAddress) {
        for (const group of formGroups) {
            groups.push({
                ...group,
                groupKey: `${deliveryCustomerPrefix}${group.groupKey}`,
                sort_order: lastShortOrder,
                title: group.groupKey === "names" ? t(TranslationKeys.CART_SEPARATE_DELIVERY_ADDRESS_TITLE) : undefined,
                description: group.groupKey === "names" ? t(TranslationKeys.CART_SEPARATE_DELIVERY_ADDRESS_DESCRIPTION) : undefined,
            });
            lastShortOrder++;
        }
    }

    const generalGroup = groups.find(group => group.groupKey === "checkboxes");
    groups = groups.filter(group => group.groupKey !== "checkboxes");

    if (generalGroup) {
        generalGroup.sort_order = lastShortOrder;
        groups.push(generalGroup);
    }

    return groups;
};

const mapEventRegistrationDetailsToCustomerDetails = (items: ICartItem[]): object => {
    const details: { [key: string]: any } = {};

    const item = items.find(i => i.event_registration !== undefined);
    if (!item) return details;
    const registration = item.event_registration;
    if (registration?.firstname) {
        details["first_name"] = registration.firstname;
    }

    if (registration?.lastname) {
        details["last_name"] = registration.lastname;
    }

    if (registration?.postal_address?.addressline1) {
        details["address_line1"] = registration.postal_address?.addressline1;
    }

    if (registration?.postal_address?.addressline2) {
        details["address_line2"] = registration.postal_address?.addressline2;
    }

    if (registration?.postal_address?.postalcode) {
        details["postal_code"] = registration.postal_address?.postalcode;
    }

    if (registration?.postal_address?.city) {
        details["city"] = registration.postal_address?.city;
    }

    if (registration?.postal_address?.countrycode) {
        details["country"] = registration.postal_address?.countrycode;
    }

    if (registration?.email) {
        details["email"] = registration.email;
    }

    if (registration?.phone) {
        details["phone"] = registration.phone;
    }

    return details;
};

const CartCustomer = (props: CartCustomerProps): JSX.Element => {

    const config = useConfig();
    const dispatch = useAppDispatch();
    const {t, i18n} = useTranslation();

    const cart = useAppSelector(state => state.cart);

    const separateDeliveryAddress = config.feature_flags?.includes("enable_delivery_address") || false;

    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [fieldValues, setFieldValues] = useState<{ [key: string]: any }>({
        marketing_permission: false,
        ...mapEventRegistrationDetailsToCustomerDetails(cart.items),
        ...(cart.customer || {}),
        ...(cart.delivery_customer && Object.keys(cart.delivery_customer).length > 0 ? {
            ...Object.fromEntries(Object.entries(cart.delivery_customer).map(([k, v]) => [`${deliveryCustomerPrefix}${k}`, v])),
            enable_delivery_address: true,
        } : {}),
        comments_by_customer: cart.comments_by_customer,
    });


    const onSubmit = async (event: FormEvent) => {
        event.preventDefault();
        const cartService = new CartService();

        const validatedResult = await cartService.validateCustomerDetails(
            fieldValues,
            CartCustomerType.MAIN,
        );

        let validatedDeliveryResult;
        const deliveryCustomerObj: CustomerInterface = {};

        if (Object.keys(fieldValues).filter(field => field.startsWith(deliveryCustomerPrefix)).length > 0) {
            for (const key in fieldValues) {
                if (!fieldValues.hasOwnProperty(key)) continue;
                if (!key.startsWith(deliveryCustomerPrefix)) continue;
                deliveryCustomerObj[key.replace(deliveryCustomerPrefix, "") as keyof CustomerInterface] = fieldValues[key];
            }

            validatedDeliveryResult = await cartService.validateCustomerDetails(
                {
                    ...deliveryCustomerObj,
                    marketing_permission: false,
                },
                CartCustomerType.DELIVERY,
            );
        }

        if (Object.keys(validatedResult).length > 0 || (validatedDeliveryResult && Object.keys(validatedDeliveryResult).length > 0)) {
            console.log(validatedDeliveryResult);
            setErrors({...validatedResult, ...validatedDeliveryResult});
            return;
        }

        if (fieldValues.comments_by_customer) {
            dispatch(addCommentsByCustomer(fieldValues.comments_by_customer));
        }

        dispatch(addCustomerToCart(fieldValues, CartCustomerType.MAIN));
        if (Object.keys(deliveryCustomerObj).length > 0) {
            dispatch(addCustomerToCart(deliveryCustomerObj, CartCustomerType.DELIVERY));
            console.log(deliveryCustomerObj);
        }
    };

    const buildAvailableFields = (configFields: CustomerDetailsForm): IFormField[] => {

        const fields: IFormField[] = [];
        for (const [fieldKey, fieldValue] of Object.entries(configFields)) {
            if (!fieldValue.display) continue;
            const field = formConfig.find(f => f.fieldKey === fieldKey);

            if (!field) {
                continue;
            }

            fields.push({
                ...field,
                title: t(field.title as string),
                required: fieldValue.required,
            });

            if (separateDeliveryAddress && fieldValues["enable_delivery_address"]) {
                fields.push({
                    ...field,
                    title: t(field.title as string),
                    required: fieldValue.required,
                    fieldKey: `${deliveryCustomerPrefix}${field.fieldKey}`,
                    grouped_by: `${deliveryCustomerPrefix}${field.grouped_by}`,
                    show_depends_on_field: "enable_delivery_address",
                });
            }
        }

        if (separateDeliveryAddress) {
            fields.push({
                fieldKey: "enable_delivery_address",
                title: t(TranslationKeys.CART_SEPARATE_DELIVERY_ADDRESS_LABEL),
                type: "checkbox",
                grouped_by: FormGroup.SEPARATE_DELIVERY_CHECKBOX,
                sort_order: 10,
            });
            fields.push({
                fieldKey: `comments_by_customer`,
                title: t(TranslationKeys.CART_SEPARATE_DELIVERY_ADDRESS_MESSAGE),
                type: "textarea",
                grouped_by: `${deliveryCustomerPrefix}${FormGroup.GENERAL}`,
                show_depends_on_field: "enable_delivery_address",
                sort_order: 20,
                required: false,
            });
        }

        if (config.marketing_permission_label) {
            fields.push({
                fieldKey: "marketing_permission",
                title: config.marketing_permission_label,
                type: "checkbox",
                grouped_by: FormGroup.CHECKBOXES,
                sort_order: 11,
            });
        }

        if (config.terms_and_conditions || config.tc_acceptance_label) {
            fields.push({
                fieldKey: "accept_terms",
                title: config.tc_acceptance_label ? config.tc_acceptance_label : t(TranslationKeys.CART_ACCEPT_TERMS),
                description: {
                    fi: `<a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">${t(TranslationKeys.CART_OPEN_TERMS)}</a>`,
                },
                type: "checkbox",
                grouped_by: FormGroup.CHECKBOXES,
                sort_order: 12,
                required: true,
            });
        }


        return fields;
    };

    return <div className="card">
        <h2 className="card-title">{t(TranslationKeys.CART_CUSTOMER_DETAILS)}</h2>
        <div className="card-content border-t border-gray-200">
            <FormBuilder
                onSubmit={onSubmit}
                errors={errors}
                fields={buildAvailableFields(config.forms.customer_details_form)}
                fieldValues={fieldValues}
                setFieldValues={setFieldValues}
                groups={BuildFormGroups(separateDeliveryAddress)}
                language={i18n.language}
                formProps={{
                    id: formId,
                }}
            />
        </div>
    </div>;
};

CartCustomer.Buttons = Buttons;

export default CartCustomer;
