import React from "react";
import Layout from "../../../layout.component";
import {ArrowRightIcon, ShoppingCartIcon} from "@heroicons/react/solid";
import {TranslationKeys} from "../../../../interfaces/translation-keys.enum";
import {Link} from "gatsby";
import {useTranslation} from "react-i18next";

interface CartIsEmptyProps {

}

const CartIsEmpty = (props: CartIsEmptyProps): JSX.Element => {
    const {t} = useTranslation();

    return <Layout>
        <div className="max-w-2xl mx-auto px-4 sm:px-6 lg:px-0 py-6 lg:py-0 text-center">
            <div className="card card-content">
                <ShoppingCartIcon className="mx-auto h-16 w-16 text-gray-400"/>
                <h3 className="mt-2 text-base font-medium text-gray-900">{t(TranslationKeys.CART_IS_EMPTY)}</h3>
                <div className="mt-2">
                    <Link to="/" className="btn btn-primary btn-icon btn-icon-right">
                        {t(TranslationKeys.COMMON_BACK_TO_HOME)}
                        <ArrowRightIcon className="text-white"/>
                    </Link>
                </div>
            </div>
        </div>
    </Layout>
}
export default CartIsEmpty;
