import React, {FormEvent, useEffect} from "react";
import {DeliveryGroup} from "../../../../../interfaces/delivery-method.interface";
import {createCheckout, getAvailableDeliveryMethods, setCartStep} from "../../../../../store/actions/cart.action";
import {CartStep} from "../../../../../interfaces/cart-step";
import {CartService} from "../../../../../services/cart.service";
import DeliveryMethodCategory from "./delivery-method-category.component";
import {LoadingSpinnerIcon} from "../../../../icons/loading-spinner.icon";
import {useTranslation} from "react-i18next";
import {TranslationKeys} from "../../../../../interfaces/translation-keys.enum";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/use-redux.hook";
import {ProductType} from "../../../../../interfaces/product.interface";

interface CartDeliveryMethodsProps {
}

const Buttons = (): JSX.Element => {

    const cartService = new CartService();

    const dispatch = useAppDispatch();
    const {t} = useTranslation();

    const cart = useAppSelector(state => state.cart);

    const [disabled, setDisabled] = React.useState(true);

    useEffect(() => {
        if (cart.available_delivery_methods.length === 0) {
            setDisabled(true);
            return;
        }
        if (cart.items.find(it => it.product.type === ProductType.MERCHANDISE) && !cart.available_delivery_methods.find(adm => adm.delivery_groups.includes(DeliveryGroup.MERCHANDISE))) {
            setDisabled(true);
            return;
        }
        if (cart.items.find(it => it.product.type === ProductType.SINGLE_EVENT_ACCESS) && !cart.available_delivery_methods.find(adm => adm.delivery_groups.includes(DeliveryGroup.TICKETS))) {
            setDisabled(true);
            return;
        }

        const hasTickets: boolean = (cartService.getDefaultDeliveryMethod(DeliveryGroup.TICKETS, cart.items.map(item => item.product)) !== null);
        const hasMerchandise: boolean = (cartService.getDefaultDeliveryMethod(DeliveryGroup.MERCHANDISE, cart.items.map(item => item.product)) !== null);

        let hasSelectedTickets = !hasTickets;
        let hasSelectedMerch = !hasMerchandise;

        if (hasTickets) {
            hasSelectedTickets = cart.delivery_methods.find(method => method.delivery_group === DeliveryGroup.TICKETS) !== undefined;
        }
        if (hasMerchandise) {
            hasSelectedMerch = cart.delivery_methods.find(method => method.delivery_group === DeliveryGroup.MERCHANDISE) !== undefined;
        }

        setDisabled(!hasSelectedTickets || !hasSelectedMerch);

    }, [cart.available_delivery_methods.length, cart.delivery_methods.length]);

    const handleCheckoutButton = (event: FormEvent) => {
        event.preventDefault();
        dispatch(createCheckout());
    };

    let buttonLabel = t(cart.totalEur === 0 ? TranslationKeys.ORDER_CONFIRM : TranslationKeys.ORDER_PROCEED_TO_PAYMENT);

    return <div className="w-full grid grid-cols-2 gap-4">
        <button
            type="button"
            className="btn btn-secondary"
            onClick={() => dispatch(setCartStep(CartStep.CUSTOMER_DETAILS))}
        >
            {t(TranslationKeys.PREVIOUS_STEP)}
        </button>
        <button
            type="submit"
            className="btn btn-primary btn-icon btn-icon-left"
            disabled={disabled || cart.isLoadingDeliveryMethods || cart.isLoadingCheckout}
            title={disabled || cart.isLoadingDeliveryMethods ? t(TranslationKeys.CART_DELIVERY_METHODS_SELECT) : undefined}
            onClick={handleCheckoutButton}
        >
            {cart.isLoadingCheckout && <LoadingSpinnerIcon className="animate-spin-slow text-white"/>}
            {disabled ? t(TranslationKeys.CART_DELIVERY_METHODS_SELECT) : buttonLabel}
        </button>
    </div>;
};

const CartDeliveryMethods = (props: CartDeliveryMethodsProps): JSX.Element => {

    const dispatch = useAppDispatch();

    const isLoadingDeliveryMethods = useAppSelector(state => state.cart.isLoadingDeliveryMethods);

    useEffect(() => {
        dispatch(getAvailableDeliveryMethods());
        setTimeout(() => {
            window.scroll({top: 0, behavior: "smooth"})
        }, 0)
    }, []);

    const {t} = useTranslation();

    if (isLoadingDeliveryMethods) {
        return <div className="card card-content flex flex-col justify-center items-center text-center space-y-4">
            <LoadingSpinnerIcon className="h-16 w-16 animate-spin-slow"/>
            <p>{t(TranslationKeys.CART_DELIVERY_METHODS_LOADING)}</p>
        </div>;
    }

    return <div className="space-y-4">
        <DeliveryMethodCategory
            title={t(TranslationKeys.CART_DELIVERY_METHODS_TICKETS)}
            delivery_group={DeliveryGroup.TICKETS}
        />
        <DeliveryMethodCategory
            title={t(TranslationKeys.CART_DELIVERY_METHODS_MERCHANDISE)}
            delivery_group={DeliveryGroup.MERCHANDISE}
        />
    </div>;
};


CartDeliveryMethods.Buttons = Buttons;

export default CartDeliveryMethods;
