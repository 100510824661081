import React from "react";
import { useTranslation } from "react-i18next";
import { ICartItem } from "../../../../../interfaces/cart-item.interface";
import { translationHelper } from "../../../../../helpers/translation.helper";
import { priceHelper } from "../../../../../helpers/price.helper";
import { Link } from "gatsby";
import { TranslationKeys } from "../../../../../interfaces/translation-keys.enum";
import { removeFromCart, updateItemToCart } from "../../../../../store/actions/cart.action";
import ItemAmountSelector from "./item-amount-selector.component";
import { useAppDispatch } from "../../../../../hooks/use-redux.hook";

interface CartItemProps {
    item: ICartItem;
    showControls?: boolean;
}

const CartItem = (props: CartItemProps): JSX.Element => {

    const { item, showControls } = props;

    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch();

    const productPageLink = item.product.event_id ? `/events/${item.product.event_id}` : `/products/${item.product.auto_slug}`;

    const handleAmountChange = (value: string): void => {
        dispatch(updateItemToCart({
            ...item,
            amount: Number(value),
        }));
    };

    let price = <span title={t(TranslationKeys.CART_ITEM_PRICE)}>{priceHelper(item.price)}</span>;

    if (item.original_price !== undefined && item.original_price !== item.price) {
        price = <>
            <span
                title={t(TranslationKeys.CART_ITEM_DISCOUNTED_PRICE)}
                className="mr-2">
                {priceHelper(item.price)}
            </span>
            <span
                title={t(TranslationKeys.CART_ITEM_ORIGINAL_PRICE)}
                className="line-through">
                {priceHelper(item.original_price)}
            </span>
        </>;
    }

    return <li key={item.id} className="flex card-content">
        <div className="flex-1 flex flex-col">
            <div className="flex">
                <div className="min-w-0 flex-1">
                    <h4 className="text-sm">
                        <Link
                            to={productPageLink}
                            className="font-medium text-gray-700 hover:text-gray-800">
                            {translationHelper(item.product.title, i18n.language)}
                        </Link>
                    </h4>
                    <div className="space-x-2 space-y-2">
                        {item.customer_remarks && item.customer_remarks.split(", ").map((remark) => (
                            <span
                                className="cart-item-badge"
                                key={remark}
                            >
                                {remark}
                            </span>
                        ))}
                        {item.taken_with_campaign_codes && item.taken_with_campaign_codes.map((campaignCode, i) => (
                            <span
                                className="cart-item-badge"
                                key={campaignCode.id + i}
                            >
                                {campaignCode.code}
                            </span>
                        ))}
                        {item.event_registration && <span className="cart-item-badge">
                            {!item.event_registration.firstname && !item.event_registration.lastname ? t(TranslationKeys.COMMON_EVENT_REGISTRATION) : `${item.event_registration.firstname || ""} ${item.event_registration.lastname || ""}`}
                        </span>}
                        {item.taken_with_ticket && <span className="cart-item-badge">
                            {item.taken_with_ticket.code}
                        </span>}
                        {item.taken_with_badge && <span className="cart-item-badge">
                            {item.taken_with_badge.code}
                        </span>}
                        {item.seat_reservations && item.seat_reservations.map(reservation => <span
                                key={reservation.id}
                                className="cart-item-badge">
                                {reservation.section &&
                                <span>{t(TranslationKeys.COMMON_SEATING_CHART_SECTION, { section: translationHelper(reservation.section.title, i18n.language) })}</span>}
                                {reservation.row_number &&
                                <span>{t(TranslationKeys.COMMON_SEATING_CHART_ROW_NUMBER, { row_number: reservation.row_number })}</span>}
                                {reservation.seat_number &&
                                <span>{t(TranslationKeys.COMMON_SEATING_CHART_SEAT_NUMBER, { seat_number: reservation.seat_number })}</span>}
                            </span>,
                        )}
                    </div>
                    {item.event_registration && <Link
                        to={`/events/${item.product.event_id}`}
                        className="text-primary-600 underline">
                        {t(TranslationKeys.EVENT_REGISTRATION_ADD_ANOTHER_PARTICIPANT)}
                    </Link>}
                </div>

                {showControls && <div className="flex-1 flow-root">
                    <div>
                        <ItemAmountSelector
                            product={item.product}
                            amount={item.amount}
                            onAmountChange={handleAmountChange}
                        />
                    </div>
                    <div>
                        <button
                            type="button"
                            className="text-primary-600 hover:text-primary-500"
                            onClick={() => dispatch(removeFromCart(item.id))}
                        >
                            {t(TranslationKeys.CART_ITEM_DELETE)}
                        </button>
                    </div>
                </div>}

                <div className="ml-4 flex-shrink-0 flow-root">
                    <p className="mt-1 text-base font-medium text-gray-900">{!showControls && `${item.amount} x `}{price}</p>
                </div>
            </div>
        </div>
    </li>;
};

export default CartItem;
